import React, { useEffect } from "react";
import Hero from "../components/sites/Hero";
import About from "../components/sites/About";
import Services from "../components/sites/Services";
import ReminderNav from "../components/sites/ReminderNav";

function MainPage() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <Hero />
      <About />
      <Services />
    </>
  );
}

export default MainPage;
