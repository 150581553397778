import React from "react";
import { HashLink } from "react-router-hash-link";

function InnereMedizinLP() {
  return (
    <section id="praxen">
      <div className="padding-block-700 padding-inline text-center">
        <h1 className="praxen-header">Dermatologie</h1>
        <div className="praxis-card-list" data-type="innereMed">
          <div className="praxis-card">
            <div className="praxis-card-header">Dr. med. Susanne Richter</div>
            <div className="praxis-card-subheader">
              FÄ Haut- und Geschlechtskrankheiten <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/dermatologie-richter">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">Frau Theresa Warnke</div>
            <div className="praxis-card-subheader">
              FÄ Haut- und Geschlechtskrankheiten
              <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/dermatologie-warnke">
              Zur Praxis
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnereMedizinLP;
