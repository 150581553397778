import React from "react";
import { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import ImageGallery from "../ImageGallery";
import ExtendedServiceCard from "../template/ExtendedServiceCard";
import clockIcon from "../../assets/img/Clock_Icon.webp";
import alertIcon from "../../assets/img/2579410-alert-icon.webp";
import pinIcon from "../../assets/img/location-pin-svgrepo-com 3.webp";
import telIcon from "../../assets/img/contact_phone_img.webp";
import faxIcon from "../../assets/img/684832 1.webp";

function PraxisTemplateDetailed({ praxisInfos }) {
  const [currentOfficeTime, setCurrentOfficeTime] = useState("");
  var dayIndexArray = { Mo: 0, Di: 1, Mi: 2, Do: 3, Fr: 4, Sa: 5, So: 5 };
  var _dayIndexArray = {
    0: "Mo",
    1: "Di",
    2: "Mi",
    3: "Do",
    4: "Fr",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  useEffect(() => {
    var options = {
      weekday: "short",
      timeZone: "Europe/Berlin",
    };
    var currentWeekDay = new Date()
      .toLocaleDateString("de", options)
      .replace(".", "");

    var dayIndex = dayIndexArray[currentWeekDay];

    if (currentWeekDay !== "Sa" && currentWeekDay !== "So") {
      var _currentOfficeTime =
        praxisInfos["officeTimes"][dayIndex][currentWeekDay];
      setCurrentOfficeTime(_currentOfficeTime);
    } else {
      setCurrentOfficeTime(
        "Es werden heute leider keine Sprechzeiten angeboten."
      );
    }
  }, [praxisInfos]);

  const getDownloadFile = (file) => {
    const _file = require("../../files/" + file["file"]);
    return (
      <div
        className="btn"
        data-type="inverted"
        onClick={() => {
          window.open(_file);
        }}
      >
        {"Download " + file["title"]}
      </div>
    );
  };

  return (
    <section id="praxis-element">
      <div className="praxis-element-heading text-center">
        <div className="current-officeTime-header">
          <img src={clockIcon} alt="uhrIcon"></img>
          <span>{"Heutige Sprechzeiten:"}</span>
        </div>
        <div className="current-officeTime-text">{currentOfficeTime}</div>
        <div className="praxis-element-header">{praxisInfos["fakultat"]}</div>
        <div className="praxis-element-underline"></div>
        <div className="praxis-element-doctor">{praxisInfos["doctor"]}</div>
        <div className="praxis-element-title">{praxisInfos["title"]}</div>
        {praxisInfos["downloads"] ? (
          getDownloadFile(praxisInfos["downloads"])
        ) : (
          <></>
        )}

        <HashLink
          to="#kontakt-sprechzeiten"
          className="btn"
          data-type="inverted"
        >
          Kontakt & Sprechzeiten
        </HashLink>
      </div>
      <div className="container-gray">
        <div className="padding-block-700 padding-inline">
          <p className="praxis-element-welcome-text">
            <span className="fw-bold">Willkommen in unserer Praxis!</span>
            <br />
            Ihre Gesundheit ist unsere Priorität. Unser engagiertes Team steht
            bereit, Sie bestmöglich zu betreuen. Erfahren Sie mehr über unsere
            umfassenden Leistungen, die auf Ihre individuellen Bedürfnisse
            zugeschnitten sind.
          </p>
          <div className="praxis-element-service-wrapper">
            {praxisInfos["services"] ? (
              <>
                <div className="praxis-element-service-header">Leistungen</div>
                <div className="praxis-element-extended-service-list">
                  {praxisInfos["services"].map((service, i) => {
                    return (
                      <ExtendedServiceCard
                        serviceTitle={service["title"]}
                        serviceDescription={service["description"]}
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}

            {praxisInfos["notes"] ? (
              <div className="praxis-element-service-note">
                <img src={alertIcon} alt="noteIcon"></img>
                <span>{praxisInfos["notes"]}</span>
              </div>
            ) : (
              <></>
            )}
            {praxisInfos["extendedNotes"] ? (
              <div className="praxis-element-service-extended-note">
                <img src={alertIcon} alt="noteIcon"></img>
                {praxisInfos["extendedNotes"].map((note, i) => {
                  return <p key={i}>{note}</p>;
                })}
                <span></span>
              </div>
            ) : (
              <></>
            )}
          </div>
          {praxisInfos["imgList"].length > 0 ? (
            <div className="praxis-element-praxis-gallery">
              <div className="praxis-element-praxis-gallery-header">
                Unsere Praxis
              </div>
              <div className="praxis-element-praxis-gallery-wrapper">
                <ImageGallery
                  imgList={praxisInfos["imgList"]}
                  mobile={isMobile}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div id="kontakt-sprechzeiten">
        <div className="praxis-element-contact-wrapper padding-block-500 padding-inline">
          <div className="praxis-element-contact-info">
            <div className="praxis-element-contact-info-header">Kontakt</div>
            <div className="praxis-element-contact-info-item">
              <div className="praxis-element-contact-info-card">
                <img src={pinIcon} alt="pinIcon"></img>
              </div>
              <div className="praxis-element-contact-info-item-text">
                {praxisInfos["street"]}
                <br />
                {praxisInfos["postalCode"] + " " + praxisInfos["city"]}
              </div>
            </div>
            <div className="praxis-element-contact-info-item">
              {isMobile ? (
                <a href={"tel:+" + praxisInfos["phone"]}>
                  <div className="praxis-element-contact-info-card">
                    <img src={telIcon} alt="telIcon"></img>
                  </div>
                </a>
              ) : (
                <div className="praxis-element-contact-info-card">
                  <img src={telIcon} alt="telIcon"></img>
                </div>
              )}

              <div className="praxis-element-contact-info-item-text">
                {praxisInfos["phone"]}
              </div>
            </div>
            <div className="praxis-element-contact-info-item">
              <div className="praxis-element-contact-info-card">
                <img src={faxIcon} alt="faxIcon"></img>
              </div>
              <div className="praxis-element-contact-info-item-text">
                {praxisInfos["fax"]}
              </div>
            </div>
          </div>
          <div className="praxis-element-contact-ot">
            <div className="praxis-element-contact-ot-header">
              <img src={clockIcon} alt="uhr"></img>
              <span>Sprechzeiten</span>
            </div>
            <div className="praxis-element-contact-ot-times">
              {praxisInfos["officeTimes"].map((ot, i) => {
                if (ot[_dayIndexArray[i]]) {
                  return (
                    <div
                      className="praxis-element-contact-ot-time-element"
                      key={i}
                    >
                      {ot[_dayIndexArray[i]]}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PraxisTemplateDetailed;
