import React from "react";
import { HashLink } from "react-router-hash-link";

function InnereMedizinLP() {
  return (
    <section id="praxen">
      <div className="padding-block-700 padding-inline text-center">
        <h1 className="praxen-header">Innere Medizin</h1>
        <div className="praxis-card-list" data-type="innereMed">
          <div className="praxis-card">
            <div className="praxis-card-header">Frau Erika Metzger</div>
            <div className="praxis-card-subheader">
              FÄ Innere Medizin <br />
              Großthiemig
            </div>
            <HashLink className="btn" to="/innere-metzger">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">
              MU Dr. Gabriela Jurcic-Václaviková
            </div>
            <div className="praxis-card-subheader">
              FÄ Innere Medizin
              <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/innere-jurcic-vaclavikova">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">Herr Mirko Rost</div>
            <div className="praxis-card-subheader">
              FA Innere Medizin/Hausarzt
              <br />
              Calau
            </div>
            <HashLink className="btn" to="/innere-rost">
              Zur Praxis
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InnereMedizinLP;
