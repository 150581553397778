import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisVaclavikova() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Innere Medizin",
    doctor: "MUDr. Gabriela Jurcic-Václaviková",
    title: "Fachärztin für Innere Medizin/Hausärztin",
    services: [
      "Langzeit-EKG",
      "Langzeit-Blutdruck",
      "Blutabnahmen",
      "Ruhe-EKG",
      "Kontrolluntersuchungen DMP(Diabetes mellitus, KHK)",
      "Ultraschalluntersuchungen des Bauches (Abdomensonographie) und Sonographie der Schilddrüse",
    ],
    street: "Freiherr-vom-Stein-Str. 2",
    city: "Falkenberg/Elster",
    postalCode: "04895",
    phone: "035365 – 639 901",
    fax: "035365 – 639 903",
    officeTimes: [
      { Mo: "Mo: 8:00 – 14:00 Uhr" },
      { Di: "Di: 8:00 – 14:00 Uhr" },
      { Mi: "Mi: 13:00 – 18:00 Uhr" },
      { Do: "Do: 8:00 – 14:00 Uhr" },
      { Fr: "Fr: 8:00 – 12:00 Uhr" },
    ],
    imgList: [
      "PraxisJurcicMvzMediFalkenberg4-1024x683.webp",
      "PraxisJurcicMvzMediFalkenberg2-1024x683.webp",
    ],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisVaclavikova;
