import React from "react";
import { useMediaQuery } from "react-responsive";
import hrMobileImg from "../../assets/img/PraxisAllgemeinMvzMediFalkenberg1-mobile.webp";
import hrDesktopImg from "../../assets/img/PraxisAllgemeinMvzMediFalkenberg1-768x512.webp";
import testDesktop from "../../assets/img/desktop_new.webp";
import testMobile from "../../assets/img/mobile_new.webp";

function Hero() {
  const isMobile = useMediaQuery({ query: "(max-width: 30em)" });

  return (
    <section id="home">
      <div className="hr-group">
        <div className="hr-img-overlay"></div>

        {isMobile ? (
          <img className="hr-img" src={testMobile} alt="hr-img"></img>
        ) : (
          <></>
        )}

        {isMobile ? (
          <>
            <div className="hr-header-overlay">
              <div className="hr-header">MVZ MEDIFALK GMBH</div>
              <div className="hr-sub-header">
                Weil für uns Ihre Gesundheit an erster Stelle steht.
              </div>
            </div>
            <div className="vl-adjuster">
              <div className="vertical-line"></div>
            </div>
          </>
        ) : (
          <>
            <div className="hr-header-overlay">
              <div className="hr-header-wrapper">
                <div>
                  <div className="hr-header">MVZ</div>
                  <div className="hr-header">MEDIFALK</div>
                  <div className="hr-header">GMBH</div>
                  <div className="hr-sub-header">
                    Weil für uns Ihre Gesundheit an erster Stelle steht.
                  </div>
                </div>
                <div className="hr-header-wrapper-placeholder"></div>
              </div>
              <div className="vl-adjuster">
                <div className="vertical-line"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Hero;
