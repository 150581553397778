import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisZausinger() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Gynäkologie",
    doctor: "Dr. med. Stephanie Zaussinger",
    title: "Fachärztin für Frauenheilkunde und Geburtshilfe",

    notes: "",
    officeTimes: [
      { Mo: "Mo: 8:00 – 14:00 Uhr [Torgau]" },
      { Di: "Di: 8:00 – 18:00 Uhr [Torgau]" },
      { Mi: "Mi: 8:00 - 12:00 Uhr [Torgau]" },
      {
        Do: "Do: 8:00 bis 12:00 Uhr und 13:00 – 18:00 Uhr [Falkenberg/Elster]",
      },
      { Fr: "Fr: 8:00 – 11:00 Uhr [Falkenberg/Elster]" },
    ],
    officeLocations: [
      {
        street: "Platz der Freundschaft 6",
        city: "Torgau",
        postalCode: "04860",
        phone: "03421 – 708 292",
        fax: "03421 – 708 295",
        officeTimes: [
          { Mo: "Mo: 8:00 – 14:00 Uhr" },
          { Di: "Di: 8:00 – 18:00 Uhr" },
          { Mi: "Mi: 8:00 - 12:00 Uhr" },
          { Do: "" },
          { Fr: "" },
        ],
      },
      {
        street: "Freiherr-vom-Stein-Str. 2",
        city: "Falkenberg/Elster",
        postalCode: "04895",
        phone: "035365 – 639 901",
        fax: "035365 – 639 903",
        officeTimes: [
          { Mo: "" },
          { Di: "" },
          { Mi: "" },
          { Do: "Do: 8:00 bis 12:00 Uhr und 13:00 – 18:00 Uhr" },
          { Fr: "Fr: 8:00 – 11:00 Uhr" },
        ],
      },
    ],

    imgList: [
      "GynaekologieMvzMediFalkFalkenberg2-768x512.webp",
      "GynaekologieMvzMediFalkFalkenberg5-768x512.webp",
    ],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisZausinger;
