import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisRost() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Innere Medizin",
    doctor: "Herr Mirko Rost",
    title:
      "Facharzt für Innere Medizin/Hausarzt Notfallmedizin und Palliativmedizin",
    services: [
      "EKG auch als Monitoring",
      "LZ-EKG",
      "LZ-RR",
      "Fahrradergometrie (Belastungs-EKG)",
      "Spirometrie (Lungenfunktion)",
      "Sonographieleistungen: Abdomen, Schilddrüse, Thorax",
      "Als IGeL: Echokardiographie, Doppler/Duplex, Extracraniell, Peripher arteriell/venös",
    ],
    street: "Karl-Marx-Str. 104",
    city: "Calau",
    postalCode: "03205",
    phone: "03541 – 801 991",
    fax: "03541  -801 993",
    officeTimes: [
      { Mo: "Mo: 8:00 – 10:00 Uhr und 14:00 – 15:00 Uhr" },
      { Di: "Di: 7:30 – 10:00 Uhr" },
      { Mi: "Mi: 8:00 – 10:00 Uhr" },
      { Do: "Do: 13:00 – 14:00 Uhr" },
      { Fr: "Fr: 8:00 – 9:00 Uhr" },
    ],
    imgList: [],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisRost;
