import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

function Praxen() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <section id="praxen">
      <div className="padding-block-700 padding-inline text-center">
        <h1 className="praxen-header">Unsere Praxen</h1>
        <div className="praxis-card-list">
          <div className="praxis-card">
            <div className="praxis-card-header">Frau Erika Metzger</div>
            <div className="praxis-card-subheader">
              FÄ Innere Medizin/Hausarzt <br />
              Großthiemig
            </div>
            <HashLink className="btn" to="/innere-metzger">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">
              MU Dr. Gabriela Jurcic-Václaviková
            </div>
            <div className="praxis-card-subheader">
              FÄ Innere Medizin/Hausarzt
              <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/innere-jurcic-vaclavikova">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">Herr Mirko Rost</div>
            <div className="praxis-card-subheader">
              FA Innere Medizin/Hausarzt
              <br />
              Calau
            </div>
            <HashLink className="btn" to="/innere-rost">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">
              Dr. med. Stephanie Zaussinger
            </div>
            <div className="praxis-card-subheader">
              FÄ für Gynäkologie
              <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/gynaekologie">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">Dr. med. Susanne Richter</div>
            <div className="praxis-card-subheader">
              FÄ für Dermatologie <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/dermatologie">
              Zur Praxis
            </HashLink>
          </div>

          <div className="praxis-card">
            <div className="praxis-card-header">Dr. med. Vivien Homberg</div>
            <div className="praxis-card-subheader">
              FÄ für Neurologie, Epileptologie <br />
              Falkenberg
            </div>
            <HashLink className="btn" to="/neurologie">
              Zur Praxis
            </HashLink>
          </div>
          <div className="praxis-card">
            <div className="praxis-card-header">Dr. med. Gerrit Meier</div>
            <div className="praxis-card-subheader">
              FÄ für Kinder und Jugendmedizin
              <br />
              Herzberg
            </div>
            <HashLink className="btn" to="/kinder-jugend-meier">
              Zur Praxis
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Praxen;
