import React from "react";
import { HashLink } from "react-router-hash-link";

function About() {
  return (
    <section id="about">
      <div className="padding-block-500 padding-inline">
        <div className="text-center">
          <div className="ab-wrapper">
            <div className="fs-primary-heading fw-bold">
              Herzlich Willkommen
            </div>
            <div className="ab-text">
              <p>
                Als führendes medizinisches Versorgungszentrum bieten wir Ihnen
                hochwertige Gesundheitsdienstleistungen. Unser engagiertes Team
                aus erfahrenen Fachärzten steht für exzellente medizinische
                Versorgung in modernen Einrichtungen. Vertrauen Sie uns Ihre
                Gesundheit an – wir sind hier, um Sie bestmöglich zu betreuen.
              </p>
            </div>
            <HashLink className="btn" to="/das-mvz">
              Erfahren Sie mehr
            </HashLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
