import "./assets/styles/App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MainPage from "./pages/MainPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Praxen from "./pages/Praxen";
import PraxisMetzger from "./components/praxis_sites/InnereMedizin/PraxisMetzger";
import PraxisVaclavikova from "./components/praxis_sites/InnereMedizin/PraxisVaclavikova";
import PraxisRost from "./components/praxis_sites/InnereMedizin/PraxisRost";
import PraxisRichter from "./components/praxis_sites/Dermatologie/PraxisRichter";
import PraxisZausinger from "./components/praxis_sites/Gynaekologie/PraxisZausinger";
import PraxisHomberg from "./components/praxis_sites/Neurologie/PraxisHomberg";
import PraxisMeier from "./components/praxis_sites/Kinder_Jugend/PraxisMeier";
import PraxisWarnke from "./components/praxis_sites/Dermatologie/PraxisWarnke";
import About from "./pages/About";
import InnereMedizinLP from "./components/sites/InnereMedizinLP";
import DermatologieLP from "./components/sites/DermatologieLP";
import Datenschutz from "./pages/misc/Datenschutz";
import Impressum from "./pages/misc/Impressum";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <main>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/das-mvz" element={<About />} />
            <Route path="/praxen" element={<Praxen />} />
            <Route path="/innere-medizin" element={<InnereMedizinLP />} />
            <Route path="/innere-metzger" element={<PraxisMetzger />} />
            <Route
              path="/innere-jurcic-vaclavikova"
              element={<PraxisVaclavikova />}
            />
            <Route path="/kinder-jugend-meier" element={<PraxisMeier />} />
            <Route path="/innere-rost" element={<PraxisRost />} />
            <Route path="/gynaekologie" element={<PraxisZausinger />} />
            <Route path="/dermatologie" element={<DermatologieLP />} />
            <Route path="/dermatologie-richter" element={<PraxisRichter />} />
            <Route path="/dermatologie-warnke" element={<PraxisWarnke />} />
            <Route path="/neurologie" element={<PraxisHomberg />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
