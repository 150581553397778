import React, { useEffect } from "react";
import PraxisTemplate from "../../template/PraxisTemplate";

function PraxisHomberg() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const praxisInfos = {
    fakultat: "Neurologie",
    doctor: "Dr. med. Vivien Homberg",
    title: "Fachärztin für Neurologie | Epileptologie",
    street: "Freiherr-vom-Stein-Str. 2",
    city: "Falkenberg/Elster",
    postalCode: "04895",
    phone: "035365 – 639 901",
    fax: "035365 – 639 903",
    officeTimes: [
      { Mo: "Mo: 8:00 – 13:00 Uhr" },
      { Di: "Di: 10:00 – 15:00 Uhr" },
      { Mi: "Mi: 9:00 – 14:00 Uhr" },
      { Do: "Do: nach Vereinbarung" },
      { Fr: "" },
    ],
    notes: "Nervenmessung wird nicht angeboten",
    imgList: ["Neurologie.webp"],
  };

  return <PraxisTemplate praxisInfos={praxisInfos} />;
}

export default PraxisHomberg;
