import React, { Component } from "react";

export default class Datenschutz extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "instant" });
  }

  render() {
    return (
      <div className="container text-center" id="datenschutz">
        <div className="padding-block-500">
          <div className="misc-heading">Datenschutzhinweis</div>
          <br />
          <div className="misc-paragraph">
            <h3 className="fw-bold">Allgemeiner Hinweis</h3>
            <p>
              Als Anbieter dieser Internetseiten sind wir gesetzlich
              verpflichtet, Sie über Zweck, Umfang und Art der der Erhebung und
              Verwendung Ihrer personenbezogenen Daten zu informieren. Damit Sie
              ein gutes Gefühl dafür bekommen, auf welche Art und Weise wir
              Daten erheben, verarbeiten und nutzen, möchten wir einen Überblick
              über die Datenverarbeitung geben. Bei weiteren Fragen dürfen Sie
              sich selbstverständlich jederzeit gerne an uns wenden.
            </p>
            <br />
            <h2 className="fw-bold">1. Was sind personenbezogene Daten?</h2>
            <p>
              Der Begriff der personenbezogenen Daten ist in der
              Datenschutz-Grundverordnung (im Folgenden „DSGVO“) definiert.
              Danach sind „personenbezogene Daten“ alle Informationen, die sich
              auf eine identifizierte oder identifizierbare natürliche Person
              beziehen. Darunter fallen also beispielsweise Ihr Name, Ihre
              Anschrift, Ihre Telefonnummer oder Ihr Geburtsdatum. Auch
              Informationen darüber, wie Sie diese oder andere Webseiten nutzen,
              können personenbezogene Daten sein.
            </p>
            <br />
            <h2 className="fw-bold">2. Bereitstellung der Website</h2>
            <p>
              Der Access-Log durch unseren Webserver wird vollständig
              anonymisiert. Unser System nutzt jedoch bestimmte
              Security-Plugins, die unsere Website vor Angriffen und Missbrauch
              schützen. Normale, nicht schadhafte Website-Zugriffe werden von
              den Plugins in der Regel nicht protokolliert. Soweit der Verdacht
              auf einen Angriff auf unser System besteht, werden für forensische
              Zwecke automatisiert Daten über das Computersystem des Besuchers
              protokolliert und in Firewall-Logs gespeichert. Die gespeicherten
              Daten sind die IP-Adresse, Datum und Uhrzeit, Browser User-Agent
              und der Grund der verdächtigen Aktivität.
            </p>
            <br />
            <h3 className="fw-bold">Zweck der Verarbeitung</h3>
            <p>
              Die Erhebung der Logfiles dient der Protokollierung abgewehrter
              oder schadhafter Website-Zugriffe, der Sicherstellung forensischer
              Tätigkeiten und der Sicherheit und Stabilität unserer Webseite.
            </p>
            <br />
            <h3 className="fw-bold">Rechtsgrundlage</h3>
            <p>Rechtsgrundlage ist Art. 6 Abs.1 S.1 lit. f DSGVO.</p>
            <br />
            <h3 className="fw-bold">Berechtigte Interessen</h3>
            <p>
              In den genannten Zwecken liegt auch das berechtigte Interesse an
              der Datenverarbeitung im Sinne des Art. 6 Abs.1 S.1 lit. f DSGVO.
            </p>
            <br />
            <h3 className="fw-bold">Dauer der Datenspeicherung</h3>
            <p>
              Die Daten werden gelöscht, sobald sie für die Erreichung des
              Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist in
              der Regel nach 72 Stunden der Fall.
            </p>
            <br />
            <h3 className="fw-bold">Widerspruchsmöglichkeit</h3>
            <p>
              Soweit Daten im beschriebenen Umfang erfasst werden, ist dies für
              die Sicherung und den Betrieb der Website zwingend erforderlich.
              Es besteht daher keine Widerspruchsmöglichkeit.
            </p>
            <br />
            <h2 className="fw-bold">
              3. Rechtsgrundlagen für die Erhebung und Verarbeitung
              personenbezogener Daten
            </h2>
            <p>
              Soweit wir für Verarbeitungsvorgänge personenbezogener Daten Ihre
              Einwilligung einholen, dient Art. 6 Abs. 1 S.1 lit. a DSGVO als
              Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen
              Daten.
            </p>
            <br />
            <p>
              Bei der Verarbeitung Ihrer personenbezogenen Daten zur Erfüllung
              eines Vertrages zwischen Ihnen und unserem Unternehmen dient Art.
              6 Abs. 1 S.1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für
              Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
              Maßnahmen erforderlich sind.
            </p>
            <br />
            <p>
              Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung
              einer rechtlichen Verpflichtung erforderlich ist, der unser
              Unternehmen unterliegt, dient Art. 6 Abs. 1 S.1 lit. c DSGVO als
              Rechtsgrundlage.
            </p>
            <br />
            <p>
              Ist die Verarbeitung zur Wahrung eines berechtigten Interesses
              unseres Unternehmens oder eines Dritten erforderlich und
              überwiegen die Interessen, Grundrechte und Grundfreiheiten des
              Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs.
              1 S.1 lit. f DSGVO als Rechtsgrundlage für die Verarbeitung.
            </p>
            <br />
            <h2 className="fw-bold">4. Dauer der Datenspeicherung</h2>
            <p>
              Ihre personenbezogenen Daten werden gelöscht oder gesperrt, sobald
              der Zweck der Speicherung entfällt. Eine Speicherung kann darüber
              hinaus dann erforderlich sein, wenn dies durch den europäischen
              oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen,
              Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche
              unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der
              Daten erfolgt auch dann, wenn eine durch die genannten Normen
              vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
              Erforderlichkeit zur weiteren Speicherung der Daten für einen
              Vertragsabschluss oder eine Vertragserfüllung besteht.
            </p>
            <br />
            <h2 className="fw-bold">5. Cookies</h2>
            <p>
              Cookies sind kleine Textdateien, die beim Aufruf unserer Website
              auf Ihrem Computer hinterlegt werden und eine erneute Zuordnung
              Ihres Browsers ermöglichen. Cookies speichern Informationen, wie
              beispielsweise Ihre Spracheinstellung, die Besuchsdauer auf
              unserer Website oder Ihre dort getroffenen Eingaben.
            </p>
            <br />
            <p>
              Es bestehen verschiedene Arten von Cookies. Session-Cookies sind
              vorübergehende Cookies, die in dem Internetbrowser des Users
              gespeichert werden, bis das Browserfenster geschlossen und die
              Session-Cookies damit gelöscht werden. Dauerhafte oder persistente
              Cookies werden für wiederholte Besuche verwendet und in dem
              Browser des Users für eine vordefinierte Zeit gespeichert.
              Erstanbieter-Cookies werden von der Webseite gesetzt, die der User
              besucht. Nur diese Webseite darf Informationen aus den Cookies
              lesen. Drittanbieter-Cookies werden von Organisationen gesetzt,
              die nicht Betreiber der Webseite sind, die der User besucht. Diese
              Cookies werden zum Beispiel von Marketing-Unternehmen verwendet.
            </p>
            <br />
            <p>Die auf dieser Website eingesetzten Cookies sind:</p>
            <br />
            <h3 className="fw-bold">Rechtsgrundlage</h3>
            <p>
              Rechtsgrundlage für den Einsatz von Cookies ist im Falle von für
              den Betrieb der Webseite erforderlichen Cookies Art. 6 Abs.1 S.1
              lit. f DSGVO. Im Falle von nicht erforderlichen Cookies ist die
              Rechtsgrundlage Ihre Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <br />
            <h3 className="fw-bold">Berechtigte Interessen</h3>
            <p>
              Wir verwenden auf unserer Webseite erforderliche Cookies zum
              ordnungsgemäßen Betrieb der Website und zur Bereitstellung
              grundlegender Funktionalitäten. In diesen Zwecken liegt auch unser
              berechtigte Interesse an der Datenverarbeitung im Sinne des Art. 6
              Abs.1 S.1 lit. f DSGVO.
            </p>
            <br />
            <h3 className="fw-bold">Widerspruchsmöglichkeit </h3>
            <p>
              Sie können jederzeit auch bereits gespeicherte erforderliche
              Cookies auf Ihrem Endgerät löschen. Wenn Sie den Einsatz von
              Cookies gänzlich verhindern möchten, können Sie die Annahme von
              Cookies in Ihrem Browser verweigern. Wie das im Einzelnen
              funktioniert, entnehmen Sie bitte der Anleitung Ihres
              Browser-Herstellers. Wir weisen Sie darauf hin, dass die
              Funktionalität unserer Webseite dann allerdings nicht mehr
              gewährleistet ist.
            </p>
            <br />
            <h2 className="fw-bold">6. Kontakt/Bewerbung</h2>
            <p>
              Personenbezogene Daten werden durch uns erfasst, wenn Sie uns
              diese von sich aus mitteilen, beispielsweise, wenn Sie mit uns in
              Kontakt treten. Die uns auf diese Weise übermittelten
              personenbezogenen Daten werden wir selbstverständlich
              ausschließlich für den Zweck verwenden, zu dem Sie uns diese bei
              der Kontaktierung zur Verfügung stellen.
            </p>
            <br />
            <p>
              Eine Mitteilung dieser Angaben erfolgt auf freiwilliger Basis und
              wird in diesen Fällen von Ihnen selbst initiiert. Soweit es sich
              hierbei um Angaben zu Kommunikationskanälen (beispielsweise
              E-Mail-Adresse, Telefonnummer) handelt, werden wir diese Kanäle
              nutzen, um gemäß Ihres Anliegens mit Ihnen in Kontakt zu treten.
            </p>
            <br />
            <h3 className="fw-bold">Zwecke der Verarbeitung</h3>
            <p>
              Der Zweck der Verarbeitung Ihrer Daten ist die Bearbeitung und
              Beantwortung Ihres Anliegens.
            </p>
            <br />
            <h3 className="fw-bold">Berechtigte Interessen</h3>
            <p>
              In den beschriebenen Zwecken liegt auch das berechtigte Interesse
              an der Verarbeitung.
            </p>
            <br />
            <h3 className="fw-bold">Rechtsgrundlage</h3>
            <p>
              Rechtsgrundlage für die Verarbeitung der Daten, die Sie im Zuge
              der Kontaktaufnahme an uns übermitteln, ist Art. 6 Abs.1 S.1 lit.
              f DSGVO.
            </p>
            <br />
            <h3 className="fw-bold">Dauer der Datenspeicherung</h3>
            <p>
              Wir werden Ihre Daten, die wir im Rahmen der Kontaktaufnahme
              erhalten haben, löschen, sobald sie für die Erreichung des Zwecks
              ihrer Erhebung nicht mehr benötigt werden, Ihr Anliegen also
              vollständig bearbeitet ist und keine weitere Kommunikation mit
              Ihnen erforderlich ist oder von ihnen gewünscht wird.
            </p>
            <br />
            <h3 className="fw-bold">Widerspruchsmöglichkeit</h3>
            <p>
              Wegen einer Löschung der Daten zu Ihrer Anfrage können Sie sich
              jederzeit an unseren Datenschutzbeauftragten wenden (Kontakt siehe
              unten). Ggf. können wir dann Ihr Anliegen jedoch nicht vollständig
              bearbeiten.
            </p>
            <br />
            <h2 className="fw-bold">7. Datenübermittlungen an Dritte</h2>
            <p>
              Eine Übermittlung Ihrer Daten an Dritte findet grundsätzlich nicht
              statt, es sei denn, wir sind gesetzlich dazu verpflichtet. Soweit
              externe Dienstleister mit Ihren personenbezogenen Daten in
              Berührung kommen, haben wir durch rechtliche, technische und
              organisatorische Maßnahmen sowie durch regelmäßige Kontrollen
              sichergestellt, dass diese die Vorschriften der Datenschutzgesetze
              einhalten. Außerdem dürfen diese Dienstleister Ihre Daten nur
              gemäß unseres Auftrags verwenden.
            </p>
            <br />
            <h2 className="fw-bold">8. Ihre Rechte</h2>
            <p>
              Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie
              betreffende personenbezogene Daten durch uns verarbeitet werden.
              Ist dies der Fall, geben wir Ihnen gerne Auskunft über diese
              personenbezogenen Daten und die in Art. 15 DSGVO aufgeführten
              Informationen. Darüber hinaus steht Ihnen unter den jeweiligen
              gesetzlichen Voraussetzungen das Recht auf Berichtigung (Art. 16
              DSGVO), das Recht auf Einschränkung der Verarbeitung (Art. 18
              DSGVO), das Recht auf Löschung (Art. 17 DSGVO) und das Recht auf
              Datenübertragbarkeit (Art. 20 DSGVO) zu.
            </p>
            <br />
            <p>
              Sie haben unter den gesetzlichen Voraussetzungen das Recht
              Widerspruch gegen die Verarbeitung einzulegen (Art. 21 DSGVO).
            </p>
            <br />
            <p>
              Unbeschadet dieser Rechte und der Möglichkeit einer Geltendmachung
              eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
              Rechtsbehelfs, haben Sie jederzeit die Möglichkeit, Ihr Recht auf
              Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des
              Orts des mutmaßlichen Verstoßes, geltend zu machen, wenn Sie der
              Ansicht sind, dass die Verarbeitung der Sie betreffenden
              personenbezogenen Daten gegen datenschutzrechtliche Vorschriften
              verstößt (Art. 77 DSGVO).
            </p>
            <br />
            <h2 className="fw-bold">9. Verantwortlicher</h2>
            <p>
              Die Verantwortliche im Sinne der Datenschutz-Grundverordnung
              (DSGVO) ist die
            </p>
            <br />
            <p>MVZ MediFalk GmbH</p>
            <p>Geschäftsführer: Uwe Schrader</p>
            <p>Freiherr-vom-Stein-Str. 2, 04895 Falkenberg/Elster</p>
            <p>Tel. +49( 0) 3531 – 516 029 3</p>
            <br />
            <h3 className="fw-bold">Datenschutzbeauftragte(r)</h3>
            <p>
              Für diese Anliegen steht Ihnen unser(e) Datenschutzbeauftragte(r)
              gerne zur Verfügung, den sie unter vorgenannter Anschrift oder
              folgender E-Mail-Adresse kontaktieren können:
              datenschutz@ccgmbh.eu
            </p>
            <br />
            <h3 className="fw-bold">Links zu Webseiten anderer Anbieter</h3>
            <p>
              Unsere Webseiten können Links zu Webseiten anderer Anbieter
              enthalten, auf die sich diese Datenschutzerklärung nicht
              erstreckt. Soweit mit der Nutzung der Internetseiten anderer
              Anbieter die Erhebung, Verarbeitung oder Nutzung personenbezogener
              Daten verbunden ist, beachten Sie bitte die Datenschutzhinweise
              der jeweiligen Anbieter.
            </p>
            <br />
            <h3 className="fw-bold">Änderungen der Datenschutzerklärung</h3>
            <p>
              Wir behalten uns das Recht vor, diese Datenschutzerklärung
              jederzeit unter Beachtung der geltenden Datenschutzvorschriften zu
              ändern. Derzeitiger Stand ist Januar 2024.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
