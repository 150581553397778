import React from "react";
import { HashLink } from "react-router-hash-link";

function Footer() {
  const YEAR = new Date().getFullYear();

  return (
    <footer>
      <div className="primary-footer-wrapper">
        <div className="primary-footer-nav">
          <div className="footer-wrapper">
            <HashLink to="/impressum">Impressum</HashLink>
            <HashLink to="/datenschutz">Datenschutz</HashLink>
          </div>
        </div>
        <div className="primary-footer-links">
          <p>{"© " + YEAR + " MediFalk GmbH"}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
