import React, { useState } from "react";
import chevronDown from "../../assets/img/chevron_clean_down.webp";
import chevronUp from "../../assets/img/chevron_clean_up.webp";

function ExtendedServiceCard({ serviceTitle, serviceDescription }) {
  const [cardClosed, setCardClosed] = useState(true);

  const handleClick = () => {
    setCardClosed(!cardClosed);
  };

  return (
    <div className="praxis-extended-service-card" onClick={handleClick}>
      <div className="praxis-extended-service-card-header-wrapper">
        <div className="praxis-extended-service-card-header">
          {serviceTitle}
        </div>
        <img
          className="praxis-extended-service-card-control"
          src={cardClosed ? chevronDown : chevronUp}
          alt="cardChevron"
        ></img>
      </div>
      <div
        className="praxis-extended-service-card-dropdown"
        data-type={cardClosed ? "" : "open"}
      >
        {serviceDescription.map((descText, j) => {
          return <p key={j}>{descText}</p>;
        })}
      </div>
    </div>
  );
}

export default ExtendedServiceCard;
