import { React } from "react";
import { HashLink } from "react-router-hash-link";

function NavbarPopUp({ MenuIndex, _ref, _isVisible }) {
  return (
    <>
      {MenuIndex === 1 ? (
        /* MVZ MENU */
        <div
          className="nav-modal"
          ref={_ref}
          data-type={_isVisible ? "" : "hidden"}
        >
          <HashLink className="nav-modal-link" to="/das-mvz">
            Über Uns
          </HashLink>
          <HashLink className="nav-modal-link" to="/aktuelles">
            Aktuelles
          </HashLink>
          <HashLink className="nav-modal-link" to="/jobs">
            Jobs
          </HashLink>
        </div>
      ) : (
        /* Praxis MENU */
        <div
          className="nav-modal"
          ref={_ref}
          data-type={_isVisible ? "" : "hidden"}
        >
          <HashLink className="nav-modal-link" to="/innere-medizin">
            Innere Medizin/ Hausarzt
          </HashLink>
          <HashLink className="nav-modal-link" to="/dermatologie">
            Dermatologie
          </HashLink>
          <HashLink className="nav-modal-link" to="/gynaekologie">
            Gynäkologie
          </HashLink>
          <HashLink className="nav-modal-link" to="/neurologie">
            Neurologie
          </HashLink>
          <HashLink className="nav-modal-link" to="/kinder-jugend-meier">
            Kinder- und Jugendmedizin
          </HashLink>
        </div>
      )}
    </>
  );
}

export default NavbarPopUp;
