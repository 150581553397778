import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import chevronUp from "../../assets/img/chevron_up.webp";
import chevronDown from "../../assets/img/chevron_down.webp";

function MobileDropDown({
  Header,
  Content,
  MobileMenuVisible,
  LinkClickFunction,
}) {
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleDropDownMenu = () => {
    setShowDropDown(!showDropDown);
  };

  const DropDownItem = (item, key) => {
    return (
      <HashLink
        className="dropdown-menu-item"
        key={key}
        to={item.link}
        onClick={LinkClickFunction}
      >
        {item.title}
      </HashLink>
    );
  };

  const DropDownList = () => {
    if (showDropDown | (MobileMenuVisible && showDropDown)) {
      const dropdownList = [];
      Content.forEach((element, i) => {
        dropdownList.push(DropDownItem(element, i));
      });
      return <div className="dropdown-menu-items">{dropdownList}</div>;
    }
    return;
  };

  return (
    <div className="dropdown-menu">
      <div className="dropdown-menu-header" onClick={toggleDropDownMenu}>
        <span>{Header.title}</span>
        <img src={showDropDown ? chevronUp : chevronDown} alt="chevron"></img>
      </div>
      {DropDownList()}
    </div>
  );
}

export default MobileDropDown;
