import React from "react";
import { HashLink } from "react-router-hash-link";
import burgerMenu from "../assets/img/burgerMenu.webp";
import { useLocation } from "react-router-dom";
import logo from "../assets/img/MVZ-Icon_RoundedTranspi.webp";
import { useState, useEffect, useRef } from "react";
import chevronUp from "../assets/img/chevron_icon_up.webp";
import chevronDown from "../assets/img/chevron_icon_down.webp";
import NavbarPopUp from "./Navbar/NavbarPopUp";
import MobileDropDown from "./Navbar/MobileDropDown";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMVZMenu, setShowMVZMenu] = useState(false);
  const [showPraxenMenu, setShowPraxenMenu] = useState(false);
  const [mvzChevronDirection, setMVZChevronDirection] = useState(chevronDown);
  const [praxenChevronDirection, setPraxenChevronDirection] =
    useState(chevronDown);
  const location = useLocation();
  const [staticNavbar, setStaticNavbar] = useState(false);

  const refPraxisMenu = useRef(null);
  const refMVZMenu = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    setShowPraxenMenu(false);
    setShowMVZMenu(false);
    setMVZChevronDirection(chevronDown);
    setPraxenChevronDirection(chevronDown);
  };

  const PraxenHeader = {
    title: "Fachrichtungen",
  };
  const PraxenLinks = [
    {
      title: "Innere Medizin/ Hausarzt",
      link: "/innere-medizin",
    },
    {
      title: "Dermatologie",
      link: "/dermatologie",
    },
    {
      title: "Gynäkologie",
      link: "/gynaekologie",
    },
    {
      title: "Neurologie",
      link: "/neurologie",
    },
    {
      title: "Kinder- und Jugendmedizin",
      link: "/kinder-jugend-meier",
    },
  ];
  const MVZHeader = {
    title: "Das MVZ",
  };
  const MVZLinks = [
    {
      title: "Über Uns",
      link: "/das-mvz",
    },
    {
      title: "Aktuelles",
      link: "/aktuelles",
    },
    {
      title: "Jobs",
      link: "/jobs",
    },
  ];

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
  };

  const onClickMVZMenu = () => {
    closePopUps();
    if (!showMVZMenu) {
      setMVZChevronDirection(chevronUp);
    } else {
      setMVZChevronDirection(chevronDown);
    }
    setShowMVZMenu(!showMVZMenu);
  };

  const onClickPraxenMenu = () => {
    closePopUps();
    if (!showPraxenMenu) {
      setPraxenChevronDirection(chevronUp);
    } else {
      setPraxenChevronDirection(chevronDown);
    }
    setShowPraxenMenu(!showPraxenMenu);
  };

  const closePopUps = () => {
    if (showMVZMenu) {
      setShowMVZMenu(false);
      setMVZChevronDirection(chevronDown);
    }

    if (showPraxenMenu) {
      setShowPraxenMenu(false);
      setPraxenChevronDirection(chevronDown);
    }

    if (showMobileMenu) {
      setShowMobileMenu(false);
    }
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-links">
          <HashLink className="nav-link" to="/" onClick={onNavLinkClicked}>
            Start
          </HashLink>

          <HashLink
            className="nav-link"
            to="/das-mvz"
            onClick={onNavLinkClicked}
          >
            Das MVZ
          </HashLink>

          <MobileDropDown
            Header={PraxenHeader}
            Content={PraxenLinks}
            MobileMenuVisible={showMobileMenu}
            LinkClickFunction={onClickPraxenMenu}
          />
        </div>
      );
    }
    return;
  };

  return (
    <header className="primary-header">
      <div className="nav">
        <HashLink to="/#top">
          <img className="nav-logo" src={logo} alt="logo" />
        </HashLink>
        <div className="nav-links">
          <div className="nav-extended-links">
            <span className="nav-link-dropdown">
              <div className="btn-icon">
                <span className="btn-icon-hl"></span>
                <div className="btn-icon-text" data-type="nav">
                  <HashLink to="/">
                    <span>Start</span>
                  </HashLink>
                </div>
              </div>
            </span>

            <span className="nav-link-dropdown">
              <div className="btn-icon">
                <span className="btn-icon-hl"></span>
                <div className="btn-icon-text" data-type="nav">
                  <HashLink to="/das-mvz">
                    <span>Das MVZ</span>
                  </HashLink>
                </div>
              </div>
            </span>

            <span className="nav-link-dropdown">
              <div className="btn-icon" onClick={onClickPraxenMenu}>
                <span className="btn-icon-hl"></span>
                <div className="btn-icon-text" data-type="nav">
                  <span>Fachrichtungen</span>
                </div>
                <img src={praxenChevronDirection} alt="Ausklappen"></img>
              </div>
              <NavbarPopUp
                MenuIndex={2}
                _ref={refPraxisMenu}
                _isVisible={showPraxenMenu}
              />
            </span>
          </div>
          <div className="nav-mobile">
            <img src={burgerMenu} alt="navMenu" onClick={openMobileMenu} />
          </div>
        </div>
      </div>
      <MobileMenu />
    </header>
  );
}

export default Navbar;
