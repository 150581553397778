import React, { useEffect } from "react";
import abImg1 from "../assets/img/PraxisAllgemeinMvzMediFalkenberg1-768x512.webp";
import abImg2 from "../assets/img/AerzteKomplettPraxisMediFalkenberg3-1024x683.webp";
import abImg3 from "../assets/img/PraxisAllgemeinMvzMediFalkenberg2-768x512.webp";

function About() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <section id="about">
      <div className="padding-block-700 padding-inline">
        <div className="ab-header">Das MVZ</div>
        <div className="even-areas" data-type="gap">
          <img className="ab-img" src={abImg2} alt="ab-img"></img>
          <div className="about-text-wrapper">
            <div className="about-text-header">Über Uns</div>
            <p className="about-text">
              In unseren medizinischen Einrichtungen, die sich an vier
              Standorten befinden, erhalten Sie stets eine umfassende und
              kompetente medizinische Versorgung durch unsere Praxisteams und
              Partner. Unser MVZ ist „Hausarzt“ und „Facharzt“ zugleich. Eine
              umfassende und kompetente Behandlung aus einer Hand ist unser
              zentrales Anliegen – deshalb ist für uns die persönliche Zuwendung
              ebenso selbstverständlich, wie der Einsatz von modernster
              Technologie. Informieren Sie sich hier über unser umfassendes
              Angebot.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
