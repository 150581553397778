import React from "react";
import { HashLink, NavHashLink } from "react-router-hash-link";

function Services() {
  return (
    <section id="services">
      <div className="padding-block-700 padding-inline">
        <div className="service-wrapper">
          <div className="fs-service-text">
            Wir kümmern uns um Ihre Gesundheit
          </div>
          <div className="ser-card-list">
            <div className="ser-card" data-pos="upper">
              <div className="ser-card-header fw-bold">Innere Medizin</div>
              <NavHashLink className="btn" to={"/innere-medizin"}>
                WEITERE INFORMATIONEN
              </NavHashLink>
            </div>
            <div className="ser-card" data-pos="upper">
              <div className="ser-card-header fw-bold">Dermatologie</div>
              <NavHashLink to={"/dermatologie"} className="btn">
                WEITERE INFORMATIONEN
              </NavHashLink>
            </div>
            <div className="ser-card" data-pos="center">
              <div className="ser-card-header fw-bold">Neurologie</div>
              <NavHashLink to={"/neurologie"} className="btn">
                WEITERE INFORMATIONEN
              </NavHashLink>
            </div>
            <div className="ser-card" data-pos="center">
              <div className="ser-card-header fw-bold">Gynäkologie</div>
              <NavHashLink to={"/gynaekologie"} className="btn">
                WEITERE INFORMATIONEN
              </NavHashLink>
            </div>
            <div className="ser-card" data-pos="lower">
              <div className="ser-card-header fw-bold">
                Kinder- und Jugendmedizin
              </div>
              <NavHashLink to={"/kinder-jugend-meier"} className="btn">
                WEITERE INFORMATIONEN
              </NavHashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
